import BasePlugin from '../BasePlugin'

export default class SendForApprovalAdjustmentRequestCommand extends BasePlugin {
  async execute () {
    let adjustmentRequestId = this.context.getParentContext().getModel().id
    let me = this
    let adjustmentRequestData = {
      'adjustmentRequestId': adjustmentRequestId,
      'actionTypeId': 2
    }

    this.context.$msgbox.close()

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SendForApprovalAdjustmentRequestCommand`,
      { 'adjustmentRequestId': adjustmentRequestId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .catch((error) => {
        switch (error.response.data.code) {
          case 'adjustment_request_not_found':
            this.context.$message.error('Заявка на коректировку не найдена')
            break
          case 'adjustment_request_draft_status_not_found':
            this.context.$message.error('Заявка на корректировку не в статусе "черновик"')
            break
          case 'adjustment_request_status_not_found':
            this.context.$message.error('Статус заявки на корректировку не существует')
            break
          case 'investment_request_in_adjustment_request_not_found':
            this.context.$message.error('Заявка на корректировку не содержит инвестиционной заявки')
            break
          default:
            this.context.$message.error('Неизвестная ошибка')
            break
        }
      })
      .then((response) => {
        if (response.data.message === 'Success approval') {
          this.context.$msgbox({
            type: 'info',
            message: 'Заявка на корректировку отправлена на согласование'
          })
          me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
            adjustmentRequestData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).then((response) => {
            me.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordAfterAdjustmentRequestApprovalCommand`,
              { 'adjustmentRequestId': adjustmentRequestId, 'async': 'true' },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
          })
          this.context.getParentContext().getCard().$emit('cancelChanges')
        }
        done()
      })
  }
}
